<template>
  <div class="col-12">
    <div class="card">
      <div class="card-body px-0">
        <div class="row mb-2 align-items-center px-1">
          <button type="button" class="btn btn-link pl-1 pr-0 mr-2" @click="$router.go(-1)">
            <i class="fa fa-arrow-left fa-lg text-dark"></i>
          </button>
          <span class="font-weight-bold h3 mr-1 mb-0">{{ $t('miracle_deal.striker') }}</span>
        </div>
        <div class="d-flex flex-column align-items-center justify-content-center mb-2">
          <p class="font-weight-bold">{{ $t('miracle_deal.how_can_i_be_s_or_k', { s_or_k: 'Striker' }) }}</p>
          <div class="col-7">
            <div class="embed-responsive embed-responsive-16by9">
              <iframe
                class="embed-responsive-item border-0"
                style="border-radius: 5px"
                :src="strikerVideo"
                title="Striker video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              >
              </iframe>
            </div>
          </div>
          <!-- <iframe
            width="560" height="315"
            style="border-radius: 5px"
            :src="strikerVideo"
            title="Striker video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          >
          </iframe> -->
        </div>
        <div class="row justify-content-between px-1">
          <div class="col-sm-12 col-md-8 col-lg-8 col-xl-8 mb-2">
            <CreateOrderForm :orderType="'mdStriker'" />
          </div>
          <div class="col-sm-12 col-md-3 col-lg-3 col-xl-3 mb-2 pt-5">
            <button @click="goToRouter('my-strikers')" class="btn btn-outline-primary text-bold-700 round mr-sm-1 mb-1 text-capitalize w-100">
              {{ $t('miracle_deal.my_deals') }}
            </button>
            <button @click="goToRouter('market-strikers')" class="btn btn-outline-primary text-bold-700 round mr-sm-1 mb-1 text-capitalize w-100">
              {{ $t('miracle_deal.waiting_deals') }}
            </button>
            <button @click="goToRouter('approved-deals')" class="btn btn-outline-primary text-bold-700 round mr-sm-1 mb-1 text-capitalize w-100">
              {{ $t('miracle_deal.approved_deals') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import CreateOrderForm from '@/components/miracle-deal/CreateOrderForm.vue';
import i18n from '@/config/_i18n';

export default {
  name: 'Striker',
  components: {
    CreateOrderForm,
  },
  computed: {
    ...mapState({
    }),
    strikerVideo() {
      if(i18n.locale === 'tr')
        // tr video link
        return "https://www.youtube.com/embed/DTr8_Za_U4c?rel=0&showinfo=0&modestbranding=1";
      // en video link
      return "https://www.youtube.com/embed/oahca8HdkzY?rel=0&showinfo=0&modestbranding=1"
    }
  },
  methods: {
    goToRouter(routerName) {
      this.$router.push({ name: routerName });
    }
  }
};
</script>
<style scoped>
.btn {
  border-radius: 2rem;
  padding: 1rem 3rem;
  font-size: 1rem;
}
</style>
