<template>
  <div>
    <h3 class="content-header-title mb-1">{{ isStrikerOrder ? $t('miracle_deal.striker_new') : $t('miracle_deal.keeper_new') }}</h3>
    <ValidationObserver v-slot="{ handleSubmit }" ref="form">
      <form method="post" @submit.prevent="handleSubmit(handleOrderSubmit)">
        <div data-wizard-type="step-content" data-wizard-state="current">
          <div class="row">
            <div class="col-xl-12">
              <div class="form-group">
                <label>{{ $t('miracle_deal.duration') }}</label>
                <button type="button" class="btn btn-link" @click="showLabelDesc($t('miracle_deal.duration'), $t('miracle_deal.duration_desc'))" :title="$t('miracle_deal.duration_desc')">
                  <i class="fa fa-info-circle fa-lg text-info"></i>
                </button>
                <div class="row">
                  <div class="col-lg-7 col-sm-12">
                    <validation-provider rules="required|min_value:0" :name="$t('miracle_deal.duration')" v-slot="{ classes, errors }">
                      <input class="form-control" :class="classes" type="number" v-model="orderForm.duration" :min="selectedDurationType.min" :max="selectedDurationType.max" :placeholder="selectedDurationType.placeHolder" />
                      <span class="error__message">{{ errors[0] }}</span>
                    </validation-provider>
                  </div>
                  <div class="col-lg-5 col-sm-12">
                    <multiselect v-model="selectedDurationType" :options="signDurations" :internal-search="false" :allow-empty="false" open-direction="bottom" deselect-label="" select-label="" selected-label="" track-by="name" label="name" placeholder="Select Type">
                      <template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
                      <template slot="option" slot-scope="{ option }">{{ option.name }}</template>
                      <template slot="noOptions">{{ 'List is empty' }}</template>
                    </multiselect>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label>{{ $t('miracle_deal.sign_duration') }}</label>
                <button type="button" class="btn btn-link" @click="showLabelDesc($t('miracle_deal.sign_duration'), $t('miracle_deal.sign_duration_desc'))" :title="$t('miracle_deal.sign_duration_desc')">
                  <i class="fa fa-info-circle fa-lg text-info"></i>
                </button>
                <div class="row">
                  <div class="col-lg-7 col-sm-12">
                    <validation-provider rules="required|min_value:0" :name="$t('miracle_deal.sign_duration')" v-slot="{ classes, errors }">
                      <input class="form-control" :class="classes" type="number" v-model="orderForm.sign_duration" :max="selectedSignDurationType.max" :min="selectedSignDurationType.min" :placeholder="selectedSignDurationType.placeHolder" />
                      <span class="error__message">{{ errors[0] }}</span>
                    </validation-provider>
                  </div>
                  <div class="col-lg-5 col-sm-12">
                    <multiselect v-model="selectedSignDurationType" :options="signDurations" :internal-search="false" :allow-empty="false" open-direction="bottom" deselect-label="" select-label="" selected-label="" track-by="name" label="name" placeholder="Select Type">
                      <template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
                      <template slot="option" slot-scope="{ option }">{{ option.name }}</template>
                      <template slot="noOptions">{{ 'List is empty' }}</template>
                    </multiselect>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label>{{ $t('miracle_deal.currency') }}</label>
                <button type="button" class="btn btn-link" @click="showLabelDesc($t('miracle_deal.currency'), $t('miracle_deal.currency_desc'))" :title="$t('miracle_deal.currency_desc')">
                  <i class="fa fa-info-circle fa-lg text-info"></i>
                </button>
                <div class="row">
                  <div class="col-12">
                    <validation-provider rules="required" :name="$t('miracle_deal.currency')" v-slot="{ classes, errors }">
                      <multiselect v-model="selectedCurrency" :options="crypto" :internal-search="true" :loading="currenciesIsLoading" @input="onChangeCurrency" open-direction="bottom" deselect-label="" select-label="" selected-label="" track-by="name" label="name" :placeholder="$t('miracle_deal.choosen_currency')" :class="classes">
                        <template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
                        <template slot="option" slot-scope="{ option }">{{ option.name }}</template>
                        <template slot="noOptions">{{ 'List is empty' }}</template>
                      </multiselect>
                      <span class="error__message">{{ errors[0] }}</span>
                    </validation-provider>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label>{{ $t('miracle_deal.quantity') }}</label>
                <button type="button" class="btn btn-link" @click="showLabelDesc($t('miracle_deal.quantity'), $t('miracle_deal.quantity_desc'))" :title="$t('miracle_deal.quantity_desc')">
                  <i class="fa fa-info-circle fa-lg text-info"></i>
                </button>
                <div class="row">
                  <div class="col-12">
                    <!-- <validation-provider rules="required" :name="$t('miracle_deal.quantity')" v-slot="{ classes, errors }">
                      <currency-input class="form-control" :placeholder="$t('miracle_deal.quantity')" :class="classes" @keyup="onChangeQuantity" :precision="currencyPrecision" v-model="orderForm.quantity" />
                      <span class="error__message">{{ errors[0] }}</span>
                      <PercentButtons @onSelectedPercent="onSelectedPercent" ref="percentButtons" class="my-sm-1" />
                    </validation-provider> -->
                    <validation-provider rules="required|integer" :name="$t('miracle_deal.quantity')" v-slot="{ classes, errors }">
                      <input class="form-control" :class="classes" type="number" min="1" v-model="orderForm.quantity" @keyup="onChangeQuantity" :placeholder="$t('miracle_deal.quantity')" />
                      <span class="error__message">{{ errors[0] }}</span>
                      <PercentButtons @onSelectedPercent="onSelectedPercent" ref="percentButtons" class="my-sm-1" />
                    </validation-provider>
                  </div>
                </div>
                <div class="d-flex justify-content-between">
                  <p class="font-small-3">{{ $t('deposit-availableLimit') }}</p>
                  <p class="font-small-3 font-weight-bold">{{ currencyBalanceFormatted }}</p>
                </div>
              </div>
              <div class="form-group">
                <label>{{ $t('miracle_deal.ratio') }}</label>
                <button type="button" class="btn btn-link" @click="showLabelDesc($t('miracle_deal.ratio'), $t('miracle_deal.ratio_desc'))" :title="$t('miracle_deal.ratio_desc')">
                  <i class="fa fa-info-circle fa-lg text-info"></i>
                </button>
                <div class="row">
                  <div class="col-12">
                    <validation-provider rules="required|min_value:0|max_value:100" :name="$t('miracle_deal.ratio')" v-slot="{ classes, errors }">
                      <input type="number" min="0.01" max="100" step="0.01" pattern="^\d*(\.\d{0,2})?$" class="form-control" :class="classes" v-model="orderForm.ratio" :placeholder="`${$t('miracle_deal.ratio')}: 0.1 % - 100 %`" />
                      <span class="error__message">{{ errors[0] }}</span>
                    </validation-provider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-center mt-2 pt-10">
          <button type="submit" class="btn-gradient-success btn-block">{{ $t('miracle_deal.create_button') }}</button>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Multiselect from 'vue-multiselect';
import PercentButtons from "../../components/global/PercentButtons.vue";
import Swal from 'sweetalert2';

export default {
  name: 'CreateOrderForm',
  props: {
    orderType: {
        type: String,
        default: 'mdStriker'
    }
  },
  data() {
    return {
      orderForm: {
        duration: '',
        durationType: '',
        sign_duration: '',
        sign_duration_type: '',
        quantity: null,
        ratio: ''
      },
      selectedCurrency: null,
      selectedUser: '',
      currencyPrecision: 2,
      selectedDurationType: null,
      selectedSignDurationType: null,
      isSubmitting: false
    };
  },
  components: {
    Multiselect,
    PercentButtons
  },
  created() {
    this.GET_CURRENCY();
    this.selectedDurationType = this.signDurations[0];
    this.selectedSignDurationType = this.signDurations[0];
  },
  computed: {
    ...mapState({
      currenciesIsLoading: (state) => state.global.currenciesIsLoading,
      crypto: (state) => state.global.crypto,
      currencyBalanceFormatted: (state) => state.global.currencyBalanceFormatted,
      currencyBalance: (state) => state.global.currencyBalance,
    }),
    isStrikerOrder() {
      return this.orderType === 'mdStriker';
    },
    signDurations () {
      return [
        { value: '1', name: this.$t('commons.day'), min: 1, max: 31, placeHolder: '1 - 31' },
        { value: '2', name: this.$t('commons.month'), min: 1, max: 12, placeHolder: '1 - 12' },
        { value: '3', name: this.$t('commons.year'), min: 1, max: 100, placeHolder: '1 - 100' }
      ];
    }
  },
  methods: {
    ...mapActions('keeper', ['POST_KEEPER']),
    ...mapActions('striker', ['POST_STRIKER']),
    ...mapActions('global', ['GET_CURRENCY', 'GET_BALANCE_BY_CURRENCY']),
    handleOrderSubmit() {
      let data = {
        ...this.orderForm,
        currency_id: this.selectedCurrency?.id || '',
        duration_type: this.selectedDurationType?.value || '',
        sign_duration_type: this.selectedSignDurationType?.value || ''
      };
      this.isSubmitting = true;
      let action = this.isStrikerOrder ? this.POST_STRIKER : this.POST_KEEPER;
      action(data)
        .then((res) => {
          if(res !== "error") {
            this.resetFormData();
            this.$refs.form.reset();
          }
        })
        .catch(() => {})
        .finally(() => {
          this.isSubmitting = false;
        });
    },
    onChangeCurrency() {
      this.currencyPrecision = this.selectedCurrency?.decimal || 8;
      if(!!this.selectedCurrency && this.selectedCurrency?.code) {
        this.GET_BALANCE_BY_CURRENCY(this.selectedCurrency.code.toLowerCase());
        this.$refs.percentButtons.selectPercent("");
      }
    },
    resetFormData() {
      this.orderForm = {
        duration: '',
        durationType: '',
        sign_duration: '',
        sign_duration_type: '',
        quantity: null,
        ratio: ''
      };
      this.selectedCurrency = this.crypto[0];
      this.onChangeCurrency();
      this.selectedDurationType = this.signDurations[0];
      this.selectedSignDurationType = this.signDurations[0];
    },
    onSelectedPercent(percent) {
      if(!percent) {
        // this.orderForm.quantity = null;
        // this.$refs.form.reset();
        return;
      }
      if(parseFloat(this.currencyBalance) > 0) {
        let p = parseInt(percent);
        
        // accept digits number
        // this.orderForm.quantity = parseFloat(this.currencyBalance) * (p/100);
        this.orderForm.quantity = Math.ceil(parseFloat(this.currencyBalance) * (p/100));
        return;
      }
      this.orderForm.quantity = null;
      this.$refs.form.reset();
    },
    onChangeQuantity() {
      this.$refs.percentButtons.selectPercent("");
    },
    showLabelDesc(title, text) {
      Swal.fire({
        title: title,
        text: text,
        confirmButtonText: this.$t('modal-btn'),
        icon: 'info',
      })
    }
  },
  watch: {
    crypto: function(cryptos) {
      this.selectedCurrency = cryptos[0];
      this.onChangeCurrency();
    }
  }
};
</script>
